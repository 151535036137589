import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import { Button, ButtonGroup, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import {Link, FormattedMessage, navigate} from "gatsby-plugin-intl"
import instance from "../../components/api/httpclient";
import moment from "moment";


class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

class EditInfo2 extends React.Component {
  constructor(options) {
    super(options);
    this.state = {
      id: '',
      gender: '',
      first_name: '',
      last_name: '',
      particle_name: '',
      birthDate: '',
      street_name: '',
      house_number: '',
      house_number_ext: '',
      postal_code: '',
      city: '',
      nextButtonDisabled: false,
      formErrors: {}
    };

    let currentComponent = this;

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);

    instance.get("/me",
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (res) => {
        console.log('response', res.data.user)
        if (res.status === 200) {
          if (typeof window !== 'undefined') {
            let date = new Date(moment(res.data.user.birthDate, moment.ISO_8601).format('MM/DD/YYYY'));
            date.setHours( date.getHours() + 2 );

            currentComponent.setState({
              id: res.data.user.id,
              gender: res.data.user.gender,
              first_name: res.data.user.firstName,
              last_name: res.data.user.lastName,
              particle_name: res.data.user.particleName,
              birthDate: date,
              street_name: res.data.user.streetName,
              house_number: res.data.user.houseNumber,
              house_number_ext: res.data.user.houseNumberExt,
              postal_code: res.data.user.postalCode,
              city: res.data.user.city
            });
            currentComponent.render();
          }
        } else if (res.status === 401) {
          navigate('/sessions/logout');
        }
      });
  }

  switchBtn(e) {
    this.setState({
      gender: e.target.name
    })
  }

  handleChange(event) {
    var _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state);

    this.formValidation();
  }

  handleChangeDate(date) {
    this.setState({birthDate: date})
  }

  genericChange(params) {
    this.setState(params);
  }

  datePickerChange(date) {
    this.setState({
      birthDate: date
    })
  }

  checkboxChange() {
    let _state = {optin: !this.state.optin};
    this.setState(_state);
    this.props.genericChange(_state);
  }

  formValidation() {
    let error = false;
    let _state = {};

    if(this.state.first_name === '') {
      _state['first_name'] = 'error';
      error = true;
    } else {
      _state['first_name'] = '';
    }
    if(this.state.last_name === '') {
      _state['last_name'] = 'error';
      error = true;
    } else {
      _state['last_name'] = '';
    }
    if(this.state.gender === '') {
      _state['gender'] = 'error';
      error = true;
    } else {
      _state['gender'] = '';
    }
    if(this.state.birthDate === '') {
      _state['birthDate'] = 'error';
      error = true;
    } else {
      _state['birthDate'] = '';
    }

    if (this.state.street_name === '') {
      _state['street_name'] = 'error';
      error = true;
    } else {
      _state['street_name'] = '';
    }
    if(this.state.house_number === '') {
      _state['house_number'] = 'error';
      error = true;
    } else {
      _state['house_number'] = '';
    }
    if(this.state.postal_code === '') {
      _state['postal_code'] = 'error';
      error = true;
    } else {
      _state['postal_code'] = '';
    }
    if(this.state.city === '') {
      _state['city'] = 'error';
      error = true;
    } else {
      _state['city'] = '';
    }

    if (error === true) {
      this.setState({nextButtonDisabled: true})
    } else {
      this.setState({nextButtonDisabled: false})
    }

    this.setState({formErrors: _state});
    return error;
  }

  handleSubmit(event) {
    let that=this;

    // Submit Function
    if(!this.formValidation()) {
        const response = instance.post('/update-user-info',
          {
            "gender": parseInt(this.state.gender),
            "firstName": this.state.first_name,
            "lastName": this.state.last_name,
            "particleName": this.state.particle_name,
            "birthDate": this.state.birthDate,
            "streetName": this.state.street_name,
            "houseNumber": this.state.house_number,
            "houseNumberExt": this.state.house_number_ext,
            "postalCode": this.state.postal_code,
            "city": this.state.city,
            "lastUpdateDate": moment().format()
        },
        {
          headers: {
            'Authorization': localStorage.getItem('token'),
            'Content-Type': 'application/json',
          }
        }).then(function (response) {
          if (response.status === 200) {
            instance.post("/send-mail-info/"+ that.state.id,
            {
              gender: parseInt(that.state.gender),
              firstName: that.state.first_name,
              lastName: that.state.last_name,
              particleName: that.state.particle_name,
              birthDate: that.state.birthDate,
              streetName: that.state.street_name,
              houseNumber: that.state.house_number,
              houseNumberExt: that.state.house_number_ext,
              postalCode: that.state.postal_code,
              city: that.state.city,
              lastUpdateDate: moment().format()
            },
            {
              headers: {
                'Authorization': localStorage.getItem('token')
              }
            }
          )
                .then((res) => {})
                .catch((error) => {
                  console.log(error);
                })
            // UPDATING LOCALSTORAGE
            instance.get("/me",
              {
                headers: {
                  'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
                }
              })
              .then((res) => {

                if (res.status === 401) {
                  navigate('/sessions/logout');
                }
                if (typeof window !== 'undefined') {
                  localStorage.setItem('currentUser', JSON.stringify(res.data.user));
                  localStorage.setItem('popin', true);
                  navigate('/profile/edit-info');
                }
              });
          } else if (response.status === 401) {
            navigate('/sessions/logout');
          }
      });

    }
    event.preventDefault();
  }

  pageContent() {
    return(
      <div className="col-lg-8">

        <div className="mobile-container">
          <Link to="/profile/edit-info" className="black mb-4 mt-0 back-link"><span className="icon icon-chevron"></span><strong><FormattedMessage id="generic.nav_buttons.back_button"/></strong></Link>
        </div>
        <div className="white-container">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20"><FormattedMessage id= "my_profil.page_title"/></h1>

          <p></p>


          <form className="row">

            <div className="col-md-5">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.user_title"/></Form.Label>
                <ButtonGroup aria-label="title">
                  <Button className={this.state.gender == 1 ? "selected" : ""} name="1" onClick={this.switchBtn.bind(this)}><FormattedMessage id="generic.forms.form_options.user_title.1" /></Button>
                  <Button className={this.state.gender == 0 ? "selected" : ""} name="0" onClick={this.switchBtn.bind(this)}><FormattedMessage id="generic.forms.form_options.user_title.0" /></Button>
                  <Button className={this.state.gender == 2 ? "selected" : ""} name="2" onClick={this.switchBtn.bind(this)}><FormattedMessage id="generic.forms.form_options.user_title.2" /></Button>
                  </ButtonGroup>
              </Form.Group>
            </div>

            <div className="w-100"></div>

            <div className="col-md-5">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.first_name" /></Form.Label>
                <Form.Control type="text" name="first_name" value={this.state !== null ? this.state.first_name : ""} onChange={this.handleChange} />
              </Form.Group>
            </div>
            <div className="col-md-5">
              <Form.Group>
                <Form.Label><FormattedMessage id="generic.forms.form_field_labels.particle_name" /></Form.Label>
                <Form.Control type="text" name="particle_name" value={this.state !== null ? this.state.particle_name : ""} onChange={this.handleChange}/>
              </Form.Group>
            </div>

            <div className="w-100"></div>

            <div className="col-md-5">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.last_name" /></Form.Label>
                <Form.Control type="text" name="last_name" value={this.state !== null ? this.state.last_name : ""} onChange={this.handleChange}/>
              </Form.Group>
            </div>

            <div className="w-100"></div>

            <div className="col-md-5">
              <Form.Group controlId="formGroupEmail" className="mb-0">
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.date_of_birth" /></Form.Label>
                <DatePicker className="form-control" dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={this.state ? this.state.birthDate : new Date('01/01/1970')}
                            onChange={this.handleChangeDate}
                />
              </Form.Group>

              <Form.Group className="mb-4">
                <div className="position-relative">
                  <small className="form-text"><span className="birth-info" onClick={() => this.setState({infoOpen: !this.state.infoOpen})}><FormattedMessage id="generic.forms.form_mentions.why_date_of_birth" /></span></small>
                  {(() => {
                    if(this.state && this.state.infoOpen) {
                      return (
                        <div className="info-bulle">
                          <FormattedMessage id="1_1_1_1_login_sign_up_step1.popup_information" />
                        </div>
                      )
                    }
                  })()}
                </div>
              </Form.Group>

            </div>

            {/* ADDRESS */}
            <div className="w-100"></div>

            <div className="col-md-5">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.house_number" /></Form.Label>
                <Form.Control type="number" name="house_number" value={this.state !== null ? this.state.house_number : ""} onChange={this.handleChange} />
              </Form.Group>
            </div>
            <div className="col-md-5">
              <Form.Group>
                <Form.Label><FormattedMessage id="generic.forms.form_field_labels.house_number_ext" /></Form.Label>
                <Form.Control type="text" name="house_number_ext" value={this.state !== null ? this.state.house_number_ext : ""} onChange={this.handleChange}/>
              </Form.Group>
            </div>

            <div className="w-100"></div>

            <div className="col-md-10">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.street_name" /></Form.Label>
                <Form.Control type="text" name="street_name" value={this.state !== null ? this.state.street_name : ""} onChange={this.handleChange} />
              </Form.Group>
            </div>

            <div className="w-100"></div>

            <div className="col-md-5">
              <Form.Group>
                <Form.Label className="required"><FormattedMessage id="generic.forms.form_field_labels.postal_code" /></Form.Label>
                <Form.Control type="text" name="postal_code" value={this.state !== null ? this.state.postal_code : ""} onChange={this.handleChange} />
              </Form.Group>
            </div>
            <div className="col-md-5">
              <Form.Group>
                <Form.Label><FormattedMessage id="generic.forms.form_field_labels.city" /></Form.Label>
                <Form.Control type="text" name="city" value={this.state !== null ? this.state.city : ""} onChange={this.handleChange}/>
              </Form.Group>
            </div>

            <div className="w-100"></div>


            <div className="col-md-4">
              <div className="required-field mb-4">*<FormattedMessage id="generic.forms.form_mentions.required_fields" /></div>
            </div>

            <div className="col-md-4 ml-md-auto">
              <button type="submit" className={ this.state && this.state.nextButtonDisabled ? "disabled button red mx-auto mr-md-0" : "button red mx-auto mr-md-0" } onClick={this.handleSubmit.bind(this)}><FormattedMessage id="generic.forms.form_buttons.update"/></button>
            </div>

          </form>



        </div>
      </div>
    )
  }

  render() {
    return(
    <Layout>
      <SEO title="Edit info" />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={this.pageContent()} currentPage="edit-info-2" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
    )
  }
}

export default EditInfo2
